import React from 'react';
import ARButtonIcon from './res/ARIcon.png';

var pathname = window.location.pathname;
const singer = pathname === "/triton-sofa-single-seater";
const singerThree = pathname === "/triton-sofa-three-seater";

function ArButton({ device, color, setShowQRCode }) {

    const handleClickButton = () => {
        if (device === 'device') {
            setShowQRCode(true);
        }
    }

    let androidOneseaterGray = "https://product-configurator.ogmo.xyz/grayoneseater.glb";
    let androidOneseaterYellow = "https://product-configurator.ogmo.xyz/yellowoneseater.glb";
    let androidThreeseaterGray = "https://product-configurator.ogmo.xyz/graythreeseater.glb";
    let androidThreeseaterYellow = "https://product-configurator.ogmo.xyz/yellowthreeseater.glb";

    return (
        <>
            {device === 'android' &&
                <div className="mt-12 px-6 pb-5">
                    <button
                        className="focus:outline-none w-full h-12 bg-gradient-to-b from-butbluegrad1 to-butbluegrad2 rounded-md hover:opacity-95 ">
                        <a className="text-white text-center text-base md:text-xl" href={`intent://arvr.google.com/scene-viewer/1.0?file=${(color === 0 && singer) ? androidOneseaterGray : (color === 1 && singer) ? androidOneseaterYellow : (color === 0 && singerThree) ? androidThreeseaterGray : androidThreeseaterYellow}&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;end;`}>
                            <img className="w-7 h-7 mr-2 inline-block" src={ARButtonIcon} alt="ar icon" />
                            <label className="text-white text-center text-base md:text-xl">Try it yourself!</label>
                        </a>
                    </button>
                </div>
            }

            {(device === 'iphone' || device === 'ipad' || device === 'device') &&
                <div className="mt-12 px-6 pb-5">
                    <button
                        className="focus:outline-none w-full h-12 bg-gradient-to-b from-butbluegrad1 to-butbluegrad2 rounded-md hover:opacity-95 ">
                        <a href={((device === ('iphone' || 'ipad')) && color === 0 && singer) ? '/oneseatergray.usdz' : ((device === ('iphone' || 'ipad')) && color === 1 && singer) ? '/oneseateryellow.usdz' : ((device === ('iphone' || 'ipad')) && color === 0 && singerThree) ? '/graythreeseater.usdz' : ((device === ('iphone' || 'ipad')) && color === 1 && singerThree) ? '/yellowthreeseater.usdz' : '#'} rel="ar" onClick={handleClickButton}>
                            <img className="w-7 h-7 mr-2 inline-block" src={ARButtonIcon} alt="ar icon" />
                            <label className="text-white text-center text-base md:text-xl">Try it yourself!</label>
                        </a>
                    </button>
                </div>
            }
        </>
    );
}

export default ArButton;