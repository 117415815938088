import React from 'react';
import ogmoLogo from './res/ogmo_logo.png';
import QRcode from './res/qrcode.png';

function QRCodePopup({ setShowQRCode }) {
    return (
        <>
            <div className="absolute w-screen h-screen flex justify-center items-center z-50">
                <div className="flex w-screen/3/4 md:w-screen/2">
                    <div className="w-1/2 flex flex-col bg-white p-10 pt-5 rounded-tl-3xl rounded-bl-3xl">
                        <img className="w-screen15/2" src={ogmoLogo} alt="ogmo logo" />
                        <img src={QRcode} alt="qr code" />
                        <p className="font-popp text-sm text-center text-gray-600"> Works in ARKit supported iOS devices and ARCore supported Android devices</p>
                    </div>
                    <div className="w-1/2 flex flex-col bg-indigo-900 p-10 pt-5 rounded-tr-3xl rounded-br-3xl">
                        <button className="self-end focus:outline-none" onClick={() => setShowQRCode(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="h-full flex flex-col justify-around">
                            <div>
                                <p className="font-popp text-white text-base font-semibold mb-2 md:text-lg md:font-semibold lg:text-xl lg:font-bold xl:text-2xl xl:font-bold">You are just one step away from the magic!</p>
                            </div>
                            <div>
                                <p className="font-popp text-white text-sm lg:text-base xl:text-lg">Scan the QR code from your smartphone or tablet for Augmented Reality experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QRCodePopup;