import React, { useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { Configurator } from "./Configurator";
import { Suspense } from "react";
import { Menu } from "./Menu";
import Loader from "./Loading";
import { useState } from "react";
import { Helmet } from "react-helmet";
import QRCodePopup from "./QRCodePopup";
import { CameraControl } from './Camera'

var pathname = window.location.pathname;
const singer = pathname === "/triton-sofa-single-seater";
const singerThree = pathname === "/triton-sofa-three-seater";

let pageTitle = "3D Product Configurator | OGMO";

if (singer) {
  window.history.replaceState({}, "", '/triton-sofa-single-seater');
  pageTitle = "One Seater Configurator | Singer";
}
else if (singerThree) {
  window.history.replaceState({}, "", '/triton-sofa-three-seater');
  pageTitle = "Three Seater Configurator | Singer";
}
else {
  window.history.replaceState({}, "", '/');
}

function App() {

  const [fullScreen, setFullScreen] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  const isIPhoneDevice = /iPhone/g.test(navigator.userAgent);
  const isAndroidDevice = /android/i.test(navigator.userAgent);
  const isIPadDevice = /iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  let device = isIPhoneDevice ? "iphone" : isAndroidDevice ? "android" : isIPadDevice ? "ipad" : "device";

  useEffect(() => {
    const baseUrl = "https://2p7ll3cx01.execute-api.us-east-1.amazonaws.com/dev"
    fetch(baseUrl + '/track-configurator-views',{
      method: 'POST',
      body: JSON.stringify({
        productId: pathname.replace(/\//g,"")
      })
    })
  },[])

  const fullScreenButtonHandler = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="bg-white md:grid md:grid-cols-5 lg:grid-cols-3 xl:grid-cols-4">
        {showQRCode &&
          <QRCodePopup setShowQRCode={setShowQRCode} />
        }
        <div
          className={`bg-white fixed top-0 z-10 flex justify-center items-center rounded-3xl md:fixed transition-all duration-300 ease-in-out ${fullScreen
            ? "w-screen h-screen"
            : "w-full h-screen55 md:h-full md:w-screen/3/5 md:col-span-3 lg:w-screen/2/3 lg:col-span-2 xl:w-screen/3/4 xl:col-span-3"
            }`}
        >
          <div
            className={`absolute left-0 z-20 ml-6 ${fullScreen ? "bottom-15 pb-5" : "bottom-0 pb-9"
              } md:hidden`}
          >
            <svg
              className="w-9 h-9"
              onClick={fullScreenButtonHandler}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
              />
            </svg>
          </div>
          <div className={`${fullScreen ? 'w-full h-auto' : 'w-full h-full'}`}>
            <Canvas
              style={{ touchAction: "none" }}
              gl={{ antialias: true }}
              dpr={window.devicePixelRatio}
            >
              <Suspense fallback={<Loader />}>
                <Configurator />
              </Suspense>
              <CameraControl/>
            </Canvas>
          </div>
          <div className="hidden md:flex md:self-start pr-10 pt-5">
            <svg
              className="w-9 h-9 hover:text-gray-500"
              onClick={fullScreenButtonHandler}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
              />
            </svg>
          </div>
        </div>
        <div className="h-screen55 md:hidden"></div>
        <div className="h-screen45 md:h-auto bg-whitebgcol md:pt-4 md:col-start-4 md:col-span-2 lg:col-start-3 md:flex md:flex-col md:justify-between xl:col-start-4">
          <Menu device={device} setShowQRCode={setShowQRCode} />
        </div>
      </div>
    </>
  );
}

export default App;
