import React from "react";
import { Html } from "@react-three/drei";
import "./styles/Loading.css";

function Loading() {
  return (
    <Html>
      <div className="loder">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="86.821"
          height="21.355"
          viewBox="0 0 86.821 21.355"
        >
          <defs>
            <style>
              {`.a,.b{fill:#273962;}.a{stroke:#273962;stroke-miterlimit:10;stroke-width:0.095px;}.c{fill:url(#a);}.d,.e{fill:#f38120;}.d{opacity:0.45;}`}
            </style>
            <linearGradient
              id="a"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f59120" />
              <stop offset="0.265" stop-color="#f48c20" />
              <stop offset="0.54" stop-color="#f38023" />
              <stop offset="0.818" stop-color="#f16b26" />
              <stop offset="1" stop-color="#f05a2a" />
            </linearGradient>
          </defs>
          <g transform="translate(-810.452 -635.182)">
            <g transform="translate(810.5 635.23)">
              <path
                class="a"
                d="M831,647.835a9.827,9.827,0,0,1-.594,3.476,8.348,8.348,0,0,1-1.653,2.74,7.466,7.466,0,0,1-2.506,1.8,7.7,7.7,0,0,1-3.154.642h-4.655a7.74,7.74,0,0,1-3.16-.642,7.442,7.442,0,0,1-2.513-1.8,8.424,8.424,0,0,1-1.66-2.74,9.733,9.733,0,0,1-.6-3.476v-3.952a9.749,9.749,0,0,1,.6-3.469,8.337,8.337,0,0,1,1.66-2.74,7.585,7.585,0,0,1,2.513-1.8,7.662,7.662,0,0,1,3.16-.649h4.655a7.578,7.578,0,0,1,5.66,2.445,8.263,8.263,0,0,1,1.653,2.74,9.843,9.843,0,0,1,.594,3.469Zm-3.438-3.952a5.918,5.918,0,0,0-.324-2.012,4.524,4.524,0,0,0-.913-1.543,4.066,4.066,0,0,0-1.414-1,4.584,4.584,0,0,0-1.819-.353h-4.655a4.615,4.615,0,0,0-1.832.353,4.125,4.125,0,0,0-1.421,1,4.455,4.455,0,0,0-.919,1.543,5.916,5.916,0,0,0-.325,2.012v3.952a5.91,5.91,0,0,0,.325,2.012,4.441,4.441,0,0,0,.919,1.543,4.112,4.112,0,0,0,1.421.995,4.6,4.6,0,0,0,1.832.354h4.629a4.6,4.6,0,0,0,1.831-.354,4.125,4.125,0,0,0,1.422-.995,4.453,4.453,0,0,0,.919-1.543,5.911,5.911,0,0,0,.324-2.012Z"
                transform="translate(-810.5 -635.23)"
              />
              <path
                class="a"
                d="M881.043,656.2h-3.465V643.888l-6.1,11.694a1.572,1.572,0,0,1-.627.678,1.725,1.725,0,0,1-.867.23,1.625,1.625,0,0,1-.839-.23,1.6,1.6,0,0,1-.615-.678l-6.123-11.694V656.2h-3.438V637.125a1.965,1.965,0,0,1,.351-1.153,1.689,1.689,0,0,1,.919-.678,1.664,1.664,0,0,1,.555-.051,1.719,1.719,0,0,1,.535.13,1.608,1.608,0,0,1,.469.3,1.749,1.749,0,0,1,.358.469l7.828,14.837,7.829-14.837a1.736,1.736,0,0,1,.84-.779,1.652,1.652,0,0,1,1.118-.071,1.723,1.723,0,0,1,.912.678,1.941,1.941,0,0,1,.357,1.153Z"
                transform="translate(-816.911 -635.23)"
              />
              <path
                class="b"
                d="M907.376,647.835a9.827,9.827,0,0,1-.6,3.476,8.319,8.319,0,0,1-1.653,2.74,7.458,7.458,0,0,1-2.506,1.8,7.7,7.7,0,0,1-3.153.642h-4.655a7.746,7.746,0,0,1-3.16-.642,7.43,7.43,0,0,1-2.512-1.8,8.408,8.408,0,0,1-1.66-2.74,9.732,9.732,0,0,1-.6-3.476v-3.952a9.748,9.748,0,0,1,.6-3.469,8.322,8.322,0,0,1,1.66-2.74,7.573,7.573,0,0,1,2.512-1.8,7.667,7.667,0,0,1,3.16-.649h4.655a7.578,7.578,0,0,1,5.659,2.445,8.235,8.235,0,0,1,1.653,2.74,9.844,9.844,0,0,1,.6,3.469Zm-3.438-3.952a5.917,5.917,0,0,0-.325-2.012,4.524,4.524,0,0,0-.912-1.543,4.06,4.06,0,0,0-1.415-1,4.576,4.576,0,0,0-1.818-.353h-4.655a4.607,4.607,0,0,0-1.831.353,4.137,4.137,0,0,0-1.422,1,4.468,4.468,0,0,0-.919,1.543,5.918,5.918,0,0,0-.324,2.012v3.952a5.911,5.911,0,0,0,.324,2.012,4.453,4.453,0,0,0,.919,1.543,4.125,4.125,0,0,0,1.422.995,4.593,4.593,0,0,0,1.831.354h4.629a4.6,4.6,0,0,0,1.832-.354,4.113,4.113,0,0,0,1.421-.995,4.457,4.457,0,0,0,.919-1.543,5.911,5.911,0,0,0,.325-2.012Z"
                transform="translate(-820.602 -635.23)"
              />
            </g>
            <g transform="translate(832.209 635.372)">
              <path
                class="c"
                d="M854.044,650.336l-.01.032a7.664,7.664,0,0,1-1.391,2.47A9.575,9.575,0,0,1,845,656c-4.644-.342-8.366-4.667-8.939-9.232-.5-4.006,1.371-8.569,5.13-10.442a9.733,9.733,0,0,1,6.745-.517,10.356,10.356,0,0,1,5.094,3.229,10.542,10.542,0,0,0-6.882-.939c-.857.187-5.489,1.317-6.8,5.426-.1.3-1.2,3.99,1.152,6.544,2,2.169,5.627,2.574,6.437,1.468.793-1.083-1.487-3.117-.512-5.556a3.846,3.846,0,0,1,1.449-1.754,4.352,4.352,0,0,1,4.513.005C854.805,645.73,854.366,649.321,854.044,650.336Z"
                transform="translate(-835.579 -635.398)"
              />
              <path
                class="d"
                d="M852.893,646.693c.883,2.926-3.5,9.1-8.926,8.931-4.663-.149-8-4.92-8.4-9.247a9.8,9.8,0,0,1,17.406-7.349,11.968,11.968,0,0,0-5.08-.743,9.317,9.317,0,0,0-5.526,1.932,7.635,7.635,0,0,0-2.707,4.152,6.37,6.37,0,0,0,.793,5.245c1.781,2.365,5.526,2.965,6.431,1.849s-1.525-3.263-.493-5.19a4,4,0,0,1,4.848-1.427A3.377,3.377,0,0,1,852.893,646.693Z"
                transform="translate(-835.517 -635.393)"
              />
              <path
                class="e"
                d="M848.631,654.334"
                transform="translate(-837.252 -638.237)"
              />
              <ellipse
                class="c"
                cx="1.632"
                cy="1.668"
                rx="1.632"
                ry="1.668"
                transform="translate(6.724 8.163)"
              />
            </g>
          </g>
        </svg>

        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </Html>
  );
}

export default Loading;
